
import { defineComponent, PropType } from 'vue';

type color = 'red' | 'yellow' | 'green' | 'grey';
export default defineComponent({
  name: 'CardWithCount',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    color: {
      type: String as PropType<color>,
    },
  },
});
