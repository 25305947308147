
import { Component, Vue } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import FormSelect from '@/components/form/Select/index.vue';
import CardsModule from '@/store/cards/index';
import CardsRatingModule from '@/store/cards/rating/index';
import AppTable from '@/components/ui/table/Table.vue';
import CardWithCount from '@/components/table-items/CardWithCount.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import TimeWithClock from '@/components/table-items/TimeWithClock.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import { ref } from 'vue';
import { shortFullName } from '@/lib/Utils';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    StatusActive,
    TimeWithClock,
    TextDatetime,
    CardWithCount,
    AppTable,
    TabsNav,
    FormSelect,
  },
})
export default class RatingMain extends Vue {
  shortFullName = shortFullName;
  filter = ref(null);

  get tabsNav(): TabsNavInterface {
    return CardsModule.tabsNav;
  }

  get currentTab(): string {
    return CardsModule.tabsNav.cards_rating?.id;
  }

  get settings(): PageInterface {
    return CardsRatingModule.pageSettings;
  }

  get activeSelect() {
    return CardsRatingModule.filter.filterSettings.filterModel.active;
  }

  async handleSelect(selected: {}, params: { action: string; key: string }): Promise<void> {
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: selected,
    });

    await CardsRatingModule.filter.updateFilter();
    await CardsRatingModule.getList();
  }

  sort(header: { id: string; sort: { value: string } }): void {
    CardsRatingModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  selectAmount(value: string): void {
    CardsRatingModule.updatePageAmountItems(value);
  }

  getStatusByRole(role: string): boolean | null {
    switch (role) {
      case 'ROLE_EMPLOYEE':
        return true;
      case 'ROLE_DEACTIVATED':
        return false;
      default:
        return null;
    }
  }

  mounted(): void {
    CardsModule.initTabsItems();
    CardsRatingModule.init();
  }
}
