
import './col.scss';
import { defineComponent, h } from 'vue';

export default defineComponent({
  name: 'AppCol',
  props: {
    alignSelf: {
      validator: function (value: string) {
        return (
          [
            'center',
            'start',
            'end',
            'flex-start',
            'flex-end',
            'normal',
            'baseline',
            'first baseline',
            'last baseline',
            'stretch',
            'safe center',
            'safe center',
            'inherit',
            'initial',
            'unset',
          ].indexOf(value) !== -1
        );
      },
    },
    basic: String,
    grow: Number,
    shrink: Number,
    width: String,
    tag: {
      type: String,
      default: 'div',
    },
  },
  mounted() {
    const $parent = this.$parent as { gap?: Array<string | number> | string | number };
    if ($parent && 'gap' in $parent && $parent.gap) {
      let { gap } = $parent;
      let padding;
      if (typeof gap === 'object') {
        padding = gap.reduce((sum: string, el: string | number) => sum + el + 'px ', '');
      } else {
        padding = gap + 'px';
      }

      this.$el.style.padding = padding;
    }

    if (this.alignSelf) this.$el.style.alignSelf = this.alignSelf;
    if (this.basic) this.$el.style.flexBasis = this.basic;
    if (this.grow || this.grow === 0) this.$el.style.flexGrow = this.grow;
    if (this.shrink || this.shrink === 0) this.$el.style.flexShrink = this.shrink;
    if (this.width) this.$el.style.width = this.width;
  },
  render() {
    return h(
      this.tag,
      {
        class: ['app-col'],
      },
      this.$slots && 'default' in this.$slots ? (typeof this.$slots.default === 'function' ? this.$slots.default() : []) : []
    );
  },
});
