import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "mr-input-default__title cd-form-input-label cd-form-input-label_prompt cd-form-input-label-active"
}
const _hoisted_2 = ["data-name", "data-error", "data-error-messages"]
const _hoisted_3 = {
  key: 0,
  class: "mr-radio-option-title",
  style: {"font-size":"12px"}
}
const _hoisted_4 = {
  key: 4,
  class: "cp-cursor-pointer cp-margin__t--4"
}
const _hoisted_5 = {
  key: 0,
  class: "mr-select-default-list__wrapper cp-input-radius-25"
}
const _hoisted_6 = {
  key: 0,
  class: "mr-select-default-list mr-select-default-list__item cp-input-radius-50 mr-select-default-list_readonly"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onClick", "onKeyup"]
const _hoisted_9 = {
  key: 0,
  class: "list-item"
}
const _hoisted_10 = {
  key: 0,
  class: "mr-radio-option-title",
  style: {"font-size":"12px"}
}
const _hoisted_11 = {
  key: 1,
  class: "list-item"
}
const _hoisted_12 = {
  key: 3,
  class: "cp-hint-message"
}
const _hoisted_13 = {
  key: 4,
  class: "cp-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_icon_font = _resolveComponent("icon-font")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mr-select-default__wrapper", { disabled: _ctx.disabled, readonly: _ctx.readonly, 'cp-form-select__margin': _ctx.marginBottom }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["mr-input-default__title cd-form-input-label", {
        'cd-form-input-label-active': _ctx.ui.isLabelActive.value || _ctx.handler.value.value.value || _ctx.labelActive,
      }])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    (_ctx.prompt)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.prompt), 1))
      : _createCommentVNode("", true),
    _createElementVNode("select", {
      "data-name": _ctx.nameResult.nameInput.value,
      "data-error": _ctx.errorResult.result.value,
      "data-error-messages": _ctx.errorResult.messages.value
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      ref: "root",
      class: _normalizeClass(["mr-select-default-toggle__wrapper", {
        open: _ctx.ui.visibleItems.value,
        'cp-padding__t--no': _ctx.paddingTopZero,
      }]),
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.ui.toggle && _ctx.ui.toggle(...args)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["mr-select-default-input__wrapper cp-input-radius-50", {
          'cp-error': _ctx.errorResult.result.value || _ctx.errorResult.messages.value.length,
          'mr-select-default-input__wrapper--small': _ctx.small,
        }])
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["mr-filter-icon", _ctx.icon])
            }, null, 2))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", {
          ref: "filterListInput",
          class: _normalizeClass(["mr-select-default-input__value cp-width--full", { 'mr-select-default-input__value--small': _ctx.small }]),
          type: "text",
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handler.filter && _ctx.handler.filter(...args)))
        }, null, 34), [
          [_vShow, _ctx.handler.isSearchActive.value]
        ]),
        _withDirectives(_createElementVNode("div", {
          class: "mr-select-search-clear icon-close",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handler.clearSearch && _ctx.handler.clearSearch(...args)))
        }, null, 512), [
          [_vShow, _ctx.handler.isSearchActive.value]
        ]),
        (_ctx.tooltip)
          ? _withDirectives((_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["mr-select-default-input__value cp-width--full", { 'mr-select-default-input__value--small': _ctx.small }]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handler.enableSearch && _ctx.handler.enableSearch(...args)))
            }, [
              _createTextVNode(_toDisplayString(_ctx.handler.value.value.value || '') + "  ", 1),
              (_ctx.modelValue.duration || _ctx.modelValue.data?.duration || _ctx.isPaid)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (" + _toDisplayString(_ctx.modelValue.isPaid || _ctx.modelValue.data?.isPaid ? 'опл' : 'неопл') + ") ", 1))
                : _createCommentVNode("", true)
            ], 2)), [
              [_vShow, !_ctx.handler.isSearchActive.value]
            ])
          : _withDirectives((_openBlock(), _createElementBlock("span", {
              key: 2,
              class: _normalizeClass(["mr-select-default-input__value cp-width--full", { 'mr-select-default-input__value--small': _ctx.small }]),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handler.enableSearch && _ctx.handler.enableSearch(...args)))
            }, _toDisplayString(_ctx.handler.value.value.value || ''), 3)), [
              [_vShow, !_ctx.handler.isSearchActive.value]
            ]),
        (!_ctx.handler.isSearchActive.value)
          ? _renderSlot(_ctx.$slots, "actions", { key: 3 })
          : _createCommentVNode("", true),
        (_ctx.isLoading || _ctx.handler.loading.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(_component_gui_loader, { inline: true })
            ]))
          : _createCommentVNode("", true),
        (_ctx.filter && _ctx.clear && !!_ctx.modelValue.value && !_ctx.handler.isSearchActive.value)
          ? _renderSlot(_ctx.$slots, "default", { key: 5 }, () => [
              _createElementVNode("div", {
                class: "mr-select-default-clear icon-close",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.clearValue.clear && _ctx.clearValue.clear(...args)))
              })
            ])
          : _createCommentVNode("", true),
        (!_ctx.handler.isSearchActive.value)
          ? (_openBlock(), _createBlock(_component_arrow_down, { key: 6 }))
          : _createCommentVNode("", true)
      ], 2),
      (!_ctx.handler.loading.value && _ctx.ui.visibleItems.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (!_ctx.handler.list().length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.noResultTitle), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.handler.list(), (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.id,
                      tabindex: "0",
                      class: _normalizeClass(["mr-select-default-list mr-select-default-list__item cp-input-radius-50", { selected: _ctx.ui.getSelected(item.id) }]),
                      onClick: ($event: any) => (_ctx.handler.update(item)),
                      onKeyup: _withKeys(($event: any) => (_ctx.handler.update(item)), ["enter"])
                    }, [
                      (_ctx.tooltip)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _renderSlot(_ctx.$slots, "list-item", _normalizeProps(_guardReactiveProps(item)), () => [
                              _createTextVNode(_toDisplayString(item.value) + " ", 1),
                              (item.duration || item.data?.duration || _ctx.isPaid)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(item.isPaid || item.data?.isPaid ? 'опл' : 'неопл'), 1))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _renderSlot(_ctx.$slots, "list-item", _normalizeProps(_guardReactiveProps(item)), () => [
                              _createTextVNode(_toDisplayString(item.value), 1)
                            ])
                          ]))
                    ], 42, _hoisted_8))
                  }), 128))
                ]))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.filter && _ctx.clear && !!_ctx.modelValue.value && !_ctx.handler.isSearchActive.value)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.clearValue.clear && _ctx.clearValue.clear(...args))),
          class: "cp-cursor-pointer mr-select-default__clear"
        }, [
          _createVNode(_component_icon_font, {
            size: "10",
            icon: "close"
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.hint && !_ctx.errorResult.result.value && !_ctx.errorResult.messages.value.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.hint), 1))
      : _createCommentVNode("", true),
    (!_ctx.hideErrorMessages)
      ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.errorResult.messages.value), 1))
      : _createCommentVNode("", true)
  ], 2))
}