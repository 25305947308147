import { FormSelectUIResult } from '@/lib/formFactory/select/ui';
import { FormInputHandlerResult } from '@/lib/formFactory/select/handler';
import { Ref } from '@vue/reactivity';

export interface FormSelectClearParams {
  emit: (eventName: string, eventData: unknown) => void;
  ref: Ref<null | HTMLElement>;
  handler: FormInputHandlerResult;
  ui: FormSelectUIResult;
}

export interface FormSelectClearResult {
  clear: () => void;
}

export default function formSelectClear(params: FormSelectClearParams): FormSelectClearResult {
  const clear = () => {
    const value = {
      id: null,
      value: '',
    };

    params.emit('update:modelValue', value);
    params.emit('update', value);
    params.emit('clear', value);
    params.handler.value.value = '';
  };

  return {
    clear,
  };
}
