import { ref } from 'vue';
import { Ref } from '@vue/reactivity';

export interface FormSelectUIParams {
  modelValue: Ref<string | Record<string, unknown>>;
  isSearchActive: Ref<boolean>;
}

export interface FormSelectUIResult {
  isLabelActive: Ref<boolean>;
  visibleItems: Ref<boolean>;
  addClassIn: () => void;
  removeClassIn: () => void;
  showItems: () => void;
  hideItems: () => void;
  toggle: () => void;
  getSelected: (id: string | number) => boolean;
}

export default function formSelectUI(params: FormSelectUIParams): FormSelectUIResult {
  const isLabelActive = ref(false);
  const visibleItems = ref(false);
  const isSearchActive = ref(params.isSearchActive);

  const showItems = () => {
    visibleItems.value = true;
  };

  const hideItems = () => {
    visibleItems.value = false;
  };

  const addClassIn = () => {
    isLabelActive.value = true;
    showItems();
  };

  const removeClassIn = () => {
    isLabelActive.value = false;
    isSearchActive.value = false;
    hideItems();
  };

  const toggle = () => {
    if (isSearchActive.value) {
      isLabelActive.value = true;
      visibleItems.value = true;
    } else {
      isLabelActive.value = !isLabelActive.value;
      visibleItems.value = !visibleItems.value;
    }
  };

  const getSelected = (id: string | number) => {
    return typeof params.modelValue.value === 'object' ? params.modelValue.value.id === id : params.modelValue.value === id;
  };

  return {
    isLabelActive,
    visibleItems,

    addClassIn,
    removeClassIn,

    showItems,
    hideItems,

    toggle,

    getSelected,
  };
}
