import { ref, watch, Ref, nextTick } from 'vue';
import { FormSelectUIResult } from '@/lib/formFactory/select/ui';
import { debounce } from '@/lib/util/helpers';
import { escapeRegExpSpecialChars } from '@/lib/Utils';

export interface FormInputHandlerParams {
  emit: (eventName: string, eventData: unknown) => void;
  value: { id: string | number | null; value: string };
  ui: FormSelectUIResult;
  modelValue: Ref<string | Record<string, unknown>>;
  items: Ref<{ id: number | string; value: string }[]>;
  filterListInput: Ref<HTMLInputElement | null>;
  isSearchEnabled: Ref<boolean>;
  isSearchActive: Ref<boolean>;
  searchCallback?: Ref<(value: string) => unknown>;
  searchDebounce: Ref<number>;
}

export interface FormInputHandlerResult {
  update: (value: { id: string | number | null; value: string }) => void;
  filter: (event: { target: HTMLInputElement }) => void;
  value: Ref<string | Record<string, unknown>>;
  list: () => unknown[];
  enableSearch: () => void;
  clearSearch: () => void;
  isSearchActive: Ref<boolean>;
  searchString: Ref<string>;
  loading: Ref<boolean>;
}

export default function formInputHandler(params: FormInputHandlerParams): FormInputHandlerResult {
  const modelValue = ref(params.modelValue);
  const items = ref(params.items);
  const filterListInput = ref(params.filterListInput);
  const searchString = ref('');
  const isSearchEnabled = ref(params.isSearchEnabled);
  const isSearchActive = ref(params.isSearchActive);
  const searchCallback = ref(params.searchCallback);
  const searchDebounce = ref(params.searchDebounce);
  const loading = ref(false);
  const searchQueue = new Set();

  const debouncedSearch = debounce(async (search: string) => {
    if (!searchCallback.value) {
      return;
    }

    const searchPromise = searchCallback.value(search);
    searchQueue.add(searchPromise);

    try {
      loading.value = true;
      await searchPromise;
    } finally {
      searchQueue.delete(searchPromise);

      if (!searchQueue.size) {
        loading.value = false;
      }
    }
  }, searchDebounce.value);

  const update = (value: { id: number | string | null; value: string }) => {
    isSearchActive.value = false;
    searchString.value = '';

    params.emit('update:modelValue', value);
    params.emit('update', value);
    // TODO: Warning: Set operation on key "modelValue" failed: target is readonly.
    modelValue.value = value;

    params.ui.removeClassIn();
  };

  const filter = (event: { target: HTMLInputElement }) => {
    searchString.value = event.target.value;
  };

  const list = () => {
    const normalizedSearchString = escapeRegExpSpecialChars(searchString.value.toLowerCase());

    return items.value.filter((item: unknown) => {
      return (item as { value: string }).value.toLowerCase().search(normalizedSearchString) !== -1;
    });
  };

  const enableSearch = () => {
    if (isSearchEnabled.value) {
      isSearchActive.value = true;

      nextTick(() => {
        filterListInput.value?.focus();
        searchString.value = filterListInput.value?.value || '';
        params.ui.addClassIn();
      });
    }
  };

  const clearSearch = () => {
    isSearchActive.value = false;
    searchString.value = '';
    loading.value = false;

    if (filterListInput.value) {
      filterListInput.value.value = '';
    }
  };

  watch(
    () => searchString.value,
    async (search: string) => {
      if (!search && searchCallback.value) {
        return searchCallback.value('');
      } else if (!search) {
        return;
      }

      debouncedSearch(search);
    }
  );

  watch(
    () => [items.value, modelValue.value] as [typeof items.value, typeof modelValue.value],
    ([options, value]) => {
      if (typeof value === 'string') {
        const optionValue = options.find(({ id }) => id === value);

        if (optionValue) {
          update(optionValue);
        }
      }
    },
    { immediate: true }
  );

  return {
    update,
    filter,
    value: modelValue,
    list,
    enableSearch,
    clearSearch,
    isSearchActive,
    searchString,
    loading,
  };
}
