
import { ref, toRefs, defineComponent, PropType } from 'vue';

import uiSelectApi from '@/lib/formFactory/select/ui';
import handlerSelectApi from '@/lib/formFactory/select/handler';
import outsideSelectApi from '@/lib/formFactory/select/outside';
import validateSelectApi from '@/lib/formFactory/select/validate';
import clearSelectApi from '@/lib/formFactory/select/clear';

import nameTextFieldApi from '@/lib/formFactory/textField/name';
import rulesTextFieldApi from '@/lib/formFactory/textField/rules';
import errorTextFieldApi from '@/lib/formFactory/textField/error';

import { ParamsValidateMessages } from '@/lib/formFactory/select/validate';

import IconFont from '@/components/icons/IconFont.vue';
import ArrowDown from '@/components/icons/ArrowDown.vue';
import { GuiLoader } from '@library/gigant_ui';

export default defineComponent({
  name: 'form-select',
  emits: ['update:modelValue', 'update:isError', 'update', 'validate', 'clear'],
  props: {
    modelValue: {
      type: Object as PropType<{ id: string | number | null; value: string }>,
      default: () => {
        return { id: null, value: '' };
      },
    },
    disabled: { type: Boolean },
    label: { type: String },
    readonly: { type: Boolean },
    name: { type: String },
    prompt: { type: String },
    dataName: { type: String },
    id: { type: String },
    hint: { type: String },
    labelActive: { type: Boolean },
    validateOff: { type: Boolean },
    validateMessages: { type: Object as PropType<ParamsValidateMessages> },
    required: { type: Boolean },
    error: { default: '', type: [String, Boolean] },
    errorMessages: { default: '', type: [String, Array] },
    rules: { type: Function as PropType<() => boolean | string> },
    icon: { type: String },
    iconSize: { type: String },
    clear: { type: [Boolean, Number, String] },
    marginBottom: { type: Boolean },
    hideErrorMessages: { type: Boolean },
    filter: { type: Boolean },
    paddingTopZero: { type: Boolean },
    isLoading: { type: Boolean },
    small: { type: Boolean },
    noResultTitle: {
      type: String,
      default: 'Нет результатов',
    },
    searchDebounce: {
      type: Number,
      default: 500,
    },
    searchCallback: {
      type: Function as PropType<(value: string) => unknown>,
      default: () => Promise,
    },
    items: {
      type: Array as PropType<{ id: number | string; value: string }[]>,
      required: true,
      default: () => {
        return [];
      },
    },
    isSearchEnabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    tooltip: { type: String, default: '' },
    isPaid: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const propsRefs = toRefs(props);

    const modelValue = propsRefs.modelValue;
    const propError = propsRefs.error;
    const propErrorMessages = propsRefs.errorMessages;

    const root = ref(null);
    const filterListInput = ref(null);
    const isSearchEnabled = propsRefs.isSearchEnabled;
    const isSearchActive = ref(false);
    const searchDebounce = propsRefs.searchDebounce;
    const searchCallback = propsRefs.searchCallback;

    const ui = uiSelectApi({ modelValue, isSearchActive });

    const handler = handlerSelectApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      value: props.modelValue,
      ui,
      modelValue,
      items: propsRefs.items,
      filterListInput,
      isSearchEnabled,
      isSearchActive,
      searchDebounce,
      searchCallback,
    });

    const nameResult = nameTextFieldApi({
      dataName: propsRefs.dataName,
      label: propsRefs.label,
    });

    const clearValue = clearSelectApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      ref: root,
      handler,
      ui,
    });

    const validate = validateSelectApi({
      value: modelValue,
      validateOff: props.validateOff,
      required: props.required,
      validateMessages: props.validateMessages,
    });

    const rulesResult = rulesTextFieldApi({
      value: handler.value,
      rules: props.rules,
    });

    const errorResult = errorTextFieldApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      error: propError,
      errorMessages: propErrorMessages,
      validate: validate.validate,
      validateMessage: validate.validateMessage,
      rules: rulesResult.validate,
      rulesMessage: rulesResult.validateMessage,
    });

    const outside = outsideSelectApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      ref: root,
      ui,
      handler,
      modelValue,
      validate,
    });

    return {
      value: modelValue,
      propError,
      propErrorMessages,
      root,
      filterListInput,
      ui,
      handler,
      outside,
      validate,
      rulesResult,
      errorResult,
      clearValue,
      nameResult,
    };
  },
  components: {
    IconFont,
    ArrowDown,
    GuiLoader,
  },
});
