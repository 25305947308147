import { watch, ref, inject } from 'vue';
import { Ref } from '@vue/reactivity';

export interface ParamsValidateMessages {
  empty?: string;
}

export interface FormSelectValidateParams {
  value: Ref<string | Record<string, unknown>>;
  validateOff: boolean;
  required: boolean;
  validateMessages?: ParamsValidateMessages;
}

export interface FormSelectValidateResult {
  value: Ref<string | Record<string, unknown>>;
  validate: Ref<boolean>;
  validateMessage: Ref<string>;
  setValidate: () => boolean | void;
}

export default function formSelectValidate(params: FormSelectValidateParams): FormSelectValidateResult {
  const trigger = inject('validateInputs') as Record<string, unknown>;
  const value = params.value;

  const validateMessages = {
    empty: params.validateMessages?.empty ?? 'Поле не заполнено',
  };

  const validate = ref(true);
  const validateMessage = ref('');

  const setError = (message: string) => {
    validate.value = false;
    validateMessage.value = message;
  };

  const setSuccess = () => {
    validate.value = true;
    validateMessage.value = '';
  };

  const setValidate = () => {
    if (params.validateOff) {
      return false;
    }

    //empty
    if (
      params.required &&
      typeof value.value === 'object' &&
      'value' in value.value &&
      (value.value as { id: number; value: string }).value.length === 0
    ) {
      setError(validateMessages.empty);

      return;
    }

    setSuccess();
  };

  watch(value, setValidate);
  watch(trigger, setValidate);

  return {
    value,
    validate,
    validateMessage,
    setValidate,
  };
}
