import { Mutation, Action, Module, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

import ResponseHandlerModule from '@/store/modules/responseHandler';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { getRatingList } from '@/api/cards';

import CardsRatingFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import filterModel from './filter';
import { UITable } from '@/lib/util/tableUtils';
import { strings } from '@/lib/stringConst';
export const MODULE_NAME = 'cardsRating';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CardsRatingModule extends PageBaseModule {
  filter: Filter;

  constructor(module: CardsRatingModule) {
    super(module);

    const page = new PageEntity();
    this.pageSettings = page.values;
    page.values.actionPagination = 'cardsRating/updatePage';

    const filter = new filterModel();
    this.filter = getModule(CardsRatingFilter);
    this.filter.setFilterName('cardsRatingFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.cardsRatingSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async init() {
    this.context.commit(
      'SET_SORT',
      window.localStorage.cardsRatingSort ? JSON.parse(window.localStorage.cardsRatingSort) : {}
    );

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.filter.init();
    this.filter.filterSettings.filter = `&filters[0][id]=fullName&filters[0][value]=all`;
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getRatingList(this.pageSettings.pageCurrent, itemsQuery, sort, this.filter.filterSettings.filter);
      await this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    const result = await this.prepareTable(table);
    this.context.commit('SET_TABLE', result);
  }

  @Action({ rawError: true })
  prepareTable(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const sorts = Object.values(this.pageSettings.sort);

    return uiTable
      .removeColumn('isTest')

      .setProperty('count', 'name', 'К')
      .setProperty('lastCardDay', 'name', 'Дата')
      .setProperty('fullName', 'sortable', true)
      .setProperties('is_active', { id: 'isActive', align: 'center', name: 'А' })
      .setProperty('daysToRecovery', 'align', 'center')

      .setSortableValues(sorts)

      .getTable() as any;
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    await this.context.commit('SET_PAGE', parseInt(number));
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);

    this.context.commit('SET_SORT', sortProcessed);
    await this.getList();
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }
}

export default getModule(CardsRatingModule);
