/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
  filterHandlers = {
    update: 'cardsRatingFilter/updateFilter',
    reset: 'cardsRatingFilter/resetFilter',
    resetState: 'cardsRatingFilter/resetFilterState',
    removeSelected: 'cardsRatingFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSelectEntity('active');
    filter.setTitle('active', 'Активность');
    filter.setName('active', 'select_active');
    filter.setAction('active', 'cardsRatingFilter/updateSelect');
    filter.setList('active', [
      { id: 'all', value: 'Все' },
      { id: 'active', value: 'Активные' },
      { id: 'not_active', value: 'Заблокированные' },
    ]);
    filter.setClearAction('active', '');

    this.filterModel = filter.filterModel;
  }
}
