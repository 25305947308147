import { onUpdated, onMounted, onUnmounted } from 'vue';
import { Ref } from '@vue/reactivity';
import { FormSelectUIResult } from '@/lib/formFactory/select/ui';
import { FormInputHandlerResult } from '@/lib/formFactory/select/handler';
import { FormSelectValidateResult } from '@/lib/formFactory/select/validate';
import { FormMultiSelectUIResult } from '@/lib/formFactory/multiSelect/ui';
import { FormMultiSelectHandlerResult } from '@/lib/formFactory/multiSelect/handler';
import { FormMultiSelectValidateResult } from '@/lib/formFactory/multiSelect/validate';

interface FormSelectClickOutsideParams {
  emit: (eventName: string, eventData: unknown) => void;
  ref: Ref<null | HTMLElement>;
  ui: FormSelectUIResult | FormMultiSelectUIResult;
  handler: FormInputHandlerResult | FormMultiSelectHandlerResult;
  modelValue: Ref<unknown>;
  validate: FormSelectValidateResult | FormMultiSelectValidateResult;
  type?: string;
}

export default function formSelectClickOutside(params: FormSelectClickOutsideParams): void {
  const documentClick = (e: any) => {
    const el = params.ref.value;
    const target = e.target;

    if (params.ui.visibleItems.value) {
      if (el && el !== target && !el.contains(target)) {
        params.ui.removeClassIn();

        params.validate.setValidate();
      }
    }
  };

  const documentKeyUp = (e: any) => {
    if (e.key === 'Escape' || (params.type === 'multi' && e.key === 'Tab')) {
      params.ui.removeClassIn();

      params.validate.setValidate();
    }
  };

  onUpdated(() => {
    document.body.addEventListener('click', documentClick);
  });

  onMounted(() => {
    document.body.addEventListener('click', documentClick);
    document.body.addEventListener('keyup', documentKeyUp);
  });

  onUnmounted(() => {
    document.body.addEventListener('click', documentClick);
  });
}
