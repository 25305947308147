import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = {
  ref: "filter",
  class: "cp-flex align-items-end"
}
const _hoisted_3 = { class: "cp-flex align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_card_with_count = _resolveComponent("card-with-count")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_time_with_clock = _resolveComponent("time-with-clock")!
  const _component_status_active = _resolveComponent("status-active")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"])
          ]),
          _createElementVNode("form", _hoisted_2, [
            (_ctx.activeSelect)
              ? (_openBlock(), _createBlock(_component_form_select, {
                  key: 0,
                  modelValue: _ctx.activeSelect.current.value ? _ctx.activeSelect.current : _ctx.activeSelect.list[0],
                  items: _ctx.activeSelect.list,
                  onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelect($event, { action: _ctx.activeSelect.action, key: 'active' })))
                }, null, 8, ["modelValue", "items"]))
              : _createCommentVNode("", true)
          ], 512)
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (Object.keys(_ctx.settings.table).length > 0)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination.currentPage,
            "total-pages": _ctx.settings.table.pagination.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-count": _withCtx(({ value }) => [
              _withDirectives(_createVNode(_component_card_with_count, {
                color: "yellow",
                count: value
              }, null, 8, ["count"]), [
                [
                  _directive_tooltip,
                  'Общее кол-во активных желтых карточек',
                  void 0,
                  { right: true }
                ]
              ])
            ]),
            "item-fullName": _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'employees_schedule', params: { employeeId: item.id } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.shortFullName(value)), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTest)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            "item-lastCardDay": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-to": "DD/MM/YYYY"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-daysToRecovery": _withCtx(({ value }) => [
              value
                ? _withDirectives((_openBlock(), _createBlock(_component_time_with_clock, {
                    key: 0,
                    class: "cp-table-column__icon",
                    value: value
                  }, null, 8, ["value"])), [
                    [
                      _directive_tooltip,
                      'Время до списания следующей желтой карточки',
                      void 0,
                      { left: true }
                    ]
                  ])
                : _createCommentVNode("", true)
            ]),
            [`item-is_active`]: _withCtx(({ value }) => [
              _createVNode(_component_status_active, { "is-active": value }, null, 8, ["is-active"])
            ]),
            "item-role": _withCtx(({ value }) => [
              _createVNode(_component_status_active, {
                "is-active": _ctx.getStatusByRole(value)
              }, null, 8, ["is-active"])
            ]),
            _: 2
          }, 1032, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}