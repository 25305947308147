
import './row.scss';
import { defineComponent, h } from 'vue';

export default defineComponent({
  name: 'AppRow',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    wrap: {
      type: String,
      validator: function (value: string) {
        return ['nowrap', 'wrap', 'wrap-reverse', 'inherit', 'initial', 'unset'].indexOf(value) !== -1;
      },
    },
    direction: {
      type: String,
      validator: function (value: string) {
        return ['row', 'row-reverse', 'column', 'column-reverse', 'inherit', 'initial', 'unset'].indexOf(value) !== -1;
      },
    },
    alignItems: {
      type: String,
      validator: function (value: string) {
        return (
          [
            'center',
            'start',
            'end',
            'flex-start',
            'flex-end',
            'normal',
            'baseline',
            'first baseline',
            'last baseline',
            'stretch',
            'safe center',
            'safe center',
            'inherit',
            'initial',
            'unset',
          ].indexOf(value) !== -1
        );
      },
    },
    alignContent: {
      type: String,
      validator: function (value: string) {
        return (
          [
            'center',
            'start',
            'end',
            'flex-start',
            'flex-end',
            'normal',
            'baseline',
            'first baseline',
            'last baseline',
            'space-between',
            'space-around',
            'space-evenly',
            'stretch',
            'safe center',
            'safe center',
            'inherit',
            'initial',
            'unset',
          ].indexOf(value) !== -1
        );
      },
    },
    justifyContent: {
      type: String,
      validator: function (value: string) {
        return (
          [
            'center',
            'start',
            'end',
            'flex-start',
            'flex-end',
            'left',
            'right',
            'normal',
            'space-between',
            'space-around',
            'space-evenly',
            'stretch',
            'safe center',
            'safe center',
            'inherit',
            'initial',
            'unset',
          ].indexOf(value) !== -1
        );
      },
    },
    gap: {
      type: [Array, Number, String],
    },
  },
  mounted() {
    if (this.gap) {
      const { gap = 0 } = this as { gap?: Array<string | number> | string | number };

      this.$el.style.margin =
        typeof gap === 'object' ? gap.reduce((sum: string, el: string | number) => sum + -el + 'px ', '') : -gap + 'px';
    }

    this.$el.style.justifyContent = this.justifyContent;
    this.$el.style.alignContent = this.alignContent;
    this.$el.style.alignItems = this.alignItems;
    this.$el.style.flexWrap = this.wrap;
    this.$el.style.flexDirection = this.direction;
  },
  render() {
    return h(
      this.tag,
      {
        class: ['app-row'],
      },
      this.$slots && 'default' in this.$slots ? (typeof this.$slots.default === 'function' ? this.$slots.default() : []) : []
    );
  },
});
