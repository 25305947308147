import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';
import PageBaseModule from '@/store/page';
import pageEntity from '@/lib/layouts/page/pageEntity';

import { tabsNav } from './tabs';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import userModule from '@/store/user';

export const MODULE_NAME = 'cards';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CardsModule extends PageBaseModule {
  tabsNav: TabsNavInterface = {};

  constructor(module: CardsModule) {
    super(module);

    const page = new pageEntity();
    page.setTitle('');
    this.pageSettings = page.values;
  }

  @Mutation
  SET_TABS_NAV(tabs: TabsNavInterface) {
    this.tabsNav = tabs;
  }

  @Action({ rawError: true })
  initTabsItems() {
    const tabsByUser: TabsNavInterface = {};
    let menuItem;
    let menuItemKey;
    for ([menuItemKey, menuItem] of Object.entries(tabsNav)) {
      if (userModule.canUserRead(menuItem.pathName)) {
        tabsByUser[menuItemKey] = menuItem;
      }
    }

    this.SET_TABS_NAV(tabsByUser);
  }
}

export default getModule(CardsModule);
