<template>
  <span class="time-with-clock">
    <icon-font size="20" icon="clock" class="time-with-clock__icon cp-icon-secondary" />
    <span class="time-with-clock__text">{{ value }}д</span>
  </span>
</template>

<script>
import './timeWithClock.scss';

import { defineComponent } from 'vue';
import IconFont from '@/components/icons/IconFont';

export default defineComponent({
  name: 'TimeWithClock',
  components: { IconFont },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
});
</script>
