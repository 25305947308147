<template>
  <span class="status-active" :class="className" />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StatusActive',
  props: {
    isActive: {
      type: Boolean,
    },
  },
  computed: {
    className() {
      switch (this.isActive) {
        case true:
          return 'status-active--active';
        case false:
          return 'status-active--not-active';
        default:
          return 'status-active--indeterminate';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.status-active {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &--active {
    background-color: #27ae45;
  }

  &--not-active {
    background-color: #ff4b3a;
  }

  &--indeterminate {
    background-color: #ffc42b;
  }
}
</style>
